.startPage {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
    position: relative;
}

.conteiner {
  
    width: 100%;
    overflow: hidden;
    
}
.conteiner img {
    width: 100%;
    
}
.pic1 {
    position: relative;
    animation: change 5s  linear 1 forwards;
    z-index: -1;
    
}
.pic2 {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    animation: change3 5s  1 forwards;
}

@keyframes change {
    0% {
        opacity: 1;
       
    }
    50% {
       
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        display: none;
    }  

}
.pic3{
    width: 100%;
    position: absolute;
    top: 0;
    
    animation: change2 3s  linear 1 forwards;
}
@keyframes change2 {
    0% {
        opacity: 1;
        transform: scale(1);
       
    }
    50% {
       
        opacity: 0.5;
        transform: scale(1.15);
    }
    90% {
        opacity: 0;
        transform: scale(1.4);
        top: 0;
        z-index: -1;
    }  
    100% {
        opacity: 0;
        transform: scale(1.6);
        top: 0;
        z-index: -1;
        display: none;
    }
}
@keyframes change3 {
    0%{
        
    }
    100%{
        display: none;
    }
}
@media (max-width: 850px) {

    .startPage {
       
        height: 100%;
       
    }
   
  }
  @media (max-width: 460px) {

    .startPage {
       
        height: 100%;
       
    }
   
  }
