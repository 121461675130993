.login {
    width: 400px;
    height: 200px;
    background-color: rgb(196, 184, 169);
    margin-left: auto;
    position: absolute;
    text-align: center;
}

.login input {
    
    font-size: 16px;
    width: 200px;
}
.buttons {
    display: flex;
    justify-content: space-around;
}
.buttons button {
    width: 160px;
    height: 30px;
    font-size: 16px;
    margin-top: 60px;
    cursor: pointer;
}