.barPages {
    width: 100%;
    height: auto;
    position: relative;
    
}
.barPages p {
    z-index: 30;
}
.box1 {
    width: 100%;
    height: 100vh;
    margin-top: 250px;
    margin-bottom: 100px;
    z-index: 0;
}
.box1 h2 {
    text-transform: uppercase;
    color: white;
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 2px;
    margin-top: -40px;
    font-family: 'Roboto';
    position: absolute;
    z-index: 10;
}
.foto1 {
   
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: #0e0e0e;
}
.foto1 img {
    width: 100%;
    height: 550px;
    
}
.fotoText {
    display: flex;
    flex-direction: column;
    margin-top: 7%;
    margin-right: 10px;
    align-items: flex-end;
}

.fotoText p {
    color: white;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 100;
    margin-top: -14px;
    
}

@media (min-width: 600px) {

    .foto1 img {
        width: 600px;
        height: 780px;
        
    }
   
}