.navBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  width: 20%;
  height: 100vh;
  z-index: 1;
  background-color: #0e0e0e;
  opacity: 0.7;
  font-size: 24px;
  animation: start 2s 1 forwards;
  filter: drop-shadow(6px 6px 5px grey);
}

.text_O, .text_K, .text_N {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 20;
  font-size: 50px;
  letter-spacing: 4px;
  cursor: url('../img/logo1515.png'), pointer;
  font-family: 'Empirial';
  font-weight: 300;
  transform: scale(1);
  transition: all 1s;
}

.text_O:hover {
  transform: scale(1.1);


}

.text_S {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  z-index: 20;

}

.text_O p {
  margin: 0 0;
}

.textImg_O {


  transform: rotate(-17deg);
  width: 80px;
  margin-left: 50%;
  top: 0px;

}

.textImg_N {
  position: absolute;
  transform: rotate(-17deg);
  width: 80px;
  right: 20px;
  top: cal(40px+30%);

}

.pic {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pic img {
  width: 100%;
  height: 100%;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #0e0e0e;
  opacity: 0.1;
  z-index: 0;
}

.link {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  filter: blur(1px);
  background-size: 100% 100%;

}

.text {
  width: 100%;
  height: calc(100vh/3);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text div {

  font-family: "Calama";
  font-size: 0px;

  color: white;
  z-index: 1;
  cursor: url('../img/logo1515.png'), pointer;


}

.text img {
  position: absolute;
  width: 120%;


}

@keyframes start {
  0% {
    margin-left: -20%;
  }

  100% {
    margin-left: 0%;
  }
}
@media (max-width:1660px)  {
 
  .text_O, .text_K, .text_N {
  
    font-size: 32px;
  

  }
}
@media (max-width:860px)  {
  .navBar{
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    margin-left: 0;
    animation: start 2s 1 forwards;
  }
  .text_O, .text_K, .text_N {
    
    font-size: 46px;
    margin-top: 40px;

  }
  .text_S img {
    width: 200px;
  }
  @keyframes start {
    0% {
      margin-left: -100%;
    }
  
    100% {
      margin-left: 0%;
    }
  }
  
}