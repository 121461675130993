.navBar2 {
    overflow: hidden;
    width: 100%;
    height: 80px;
    position: fixed;
    margin-top: 40px;
    z-index: 32;
}

.conteiner {
    width: 100%;
    margin-left: 0%;
    height: 100%;
    position: relative;
}

.navig {
    width: 90%;
    margin-left: 5%;
    position: absolute;
    z-index: 20;
    color: white;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 20px;
}

.navig img {
    width: 200px;
    height: 80px;
    cursor: url('../../img/logo1515.png'), pointer;
}

.menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;

}

.menuItem {
    margin-left: 30px;
    margin-top: 25px;
    cursor: url('../../img/logo1515.png'), pointer;
    z-index: 20;
}

.back {

    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #0e0e0e;
}

.navBar2Up {

    width: 100%;
    height: 80px;
    position: fixed;
    margin-top: 40px;
    z-index: 32;
    animation: up 1s forwards;
}

.backUp {

    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #0e0e0e;
    animation: upB 1s forwards;
}

.navBar2Down {

    width: 100%;
    height: 80px;
    position: fixed;
    margin-top: 0;
    z-index: 1;
    animation: down 1s forwards;
}

.backDown {

    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #0e0e0e;
    animation: downB 1s forwards;
}

.title {

    margin-right: 20%;
    margin-top: -20px;
    z-index: 1;
    color: white;
    letter-spacing: 4px;
    font-family: 'Empirial';
    cursor: url('../../img/logo1515.png'), pointer;
    animation: title 1s 1 forwards;
}

@keyframes up {
    0% {
        margin-top: 40px;
    }

    100% {
        margin-top: 0px;
    }
}

@keyframes upB {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes down {
    0% {
        margin-top: 0px;
    }

    100% {
        margin-top: 40px;
    }
}

@keyframes downB {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes title {
    0% {
        font-size: 0px;
        opacity: 0;
    }

    100% {
        font-size: 40px;
        opacity: 1;
    }
}

@media (max-width: 1660px) {
    .title {

        margin-right: 10%;
        letter-spacing: 2px;

    }

    .conteiner {
        width: 100%;
        margin-left: 0;
    }

    .navig {
        font-size: 16px;
    }

}

@media (max-width: 1440px) {
    .title {


        letter-spacing: 2px;

    }

    .conteiner {
        width: 100%;
        margin-left: 0;
    }

    .navig {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 1200px) {
    .title {

        margin-right: 0%;
        

    }

    .title p {
        font-size: 30px;
        padding-top: 10px;
    }

    .conteiner {
        width: 100%;
        margin-left: 0;
    }

    .navig {
        width: 100%;
        margin-left: 0;
    }

    .navig img {
        padding-top: 10px;
        width: 100px;
        height: 40px;

    }
}