.menu {
    width: 100%;
    min-height: 100vh;
    margin: 0 0;
    position: relative;

}

.saveCh {
    height: 50px;
    width: 200px;
    margin-left: auto;
    background-color: red;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.menuOpacity {
    width: 100%;
    min-height: 100vh;
    margin: 0 0;
    opacity: 0.2;
}

.container {

    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 5%;
}

.container h1 {

    font-family: "Empirial";
    font-weight: 1000;
    font-size: 70px;
    letter-spacing: 6px;
}

.container h2 {
    font-family: "Empirial";
    font-weight: 800;
    font-size: 50px;
    letter-spacing: 6px;
}

.container h3 {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
}

.container select {
    margin-top: 32px;
    width: 300px;
    font-size: 24px;
    font-family: "Roboto";
    font-weight: 600;
}

.picMenu {
    transform: rotate(0);
    animation: pic 10s infinite;
}

.checkSeason {
    display: flex;
    justify-content: center;
}

.checkSeason input {
    width: 40px;
    margin-left: 10px;
    margin-top: -3px;
}

.drinks {
    width: 80%;
    margin-left: 10%;
}

.plus {

    margin-top: 16px;
    cursor: url('../../img/logo1515.png'), pointer;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.wrapperPlus {
    display: flex;
    flex-direction: column;
}

.wrapper1:hover {
    background-color: #0e0e0e;
    color: white;
    transition: all 0.6s;
}

.wrapperName {
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.hidden {
    display: flex;
    justify-content: space-evenly;
}

.drinkItem {
    margin-left: 10%;
    width: 70%;
    display: flex;
    justify-content: space-between;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 18px;

    margin-bottom: 30px;

}

.icon {
    margin-left: 20px;
}

.plusA {
    opacity: 0;
    animation: plus 1s forwards;
}

.wrapperInput {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.wrapperInput textarea {
    width: 300px;
    height: 100px;
    font-size: 18px;
}

.wrapperInput input {
    width: auto;
    height: 40px;
    margin-bottom: 10px;
    font-size: 18px;
}

@keyframes plus {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes pic {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 800px) {
    .container h1 {
        margin-top: 100px;
        font-family: "Empirial";
        font-weight: 1000;
        font-size: 70px;
        letter-spacing: 6px;
    }

    .drinks {
        width: 90%;
        margin-left: 5%;
    }

    .drinkItem {
        margin-left: 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
    }

    .wrapperName {
        width: 90%;
        display: flex;
        justify-content: space-between;
    }

    .wrapperName h3 {
        font-weight: 500;
        font-size: 16px;
        max-width: 50%;
        text-align: start;
    }

    .hidden {
        display: flex;
        justify-content: space-between;
    }
}