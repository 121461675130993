.contacts {
    width: 100%;
    height: 100vh;
    text-align: center;
    z-index: -1;
    background-color: #0e0e0e;
    overflow: hidden;

}

.header {
    display: flex;
    width: 100%;
    height: 60%;
}

.map {
    margin-top: 0;
    height: 100%;
    width: 60%;
    background-size: cover;
    filter: grayscale(90);
    opacity: 0;
    animation: upFoto 3s 1s 1 forwards;

}

.text {
    padding-top: 5%;
    color: white;
    letter-spacing: 2px;
    width: 40%;

}

.text h3 {
    margin-top: 30%;
    font-family: 'Roboto';
    font-weight: 300;
    text-transform: uppercase;
    font-size: 24px;
}

.text p {
    margin: 10px 80px;
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 20px;
}

.footer {
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: space-around;
    color: white;
    margin-top: 100px;
}
.footer img {
     width: 260px;
     height: 152px;
     margin-top: 60px;
}

.burgerM {
    width: 30%;
    margin-right: 10%;
}

.burgerM p {

    font-family: 'Roboto';
    font-weight: 200;
    font-size: 18px;
    cursor: pointer;
}
.cont {
   margin-right: 10%;  
}
.cont h3 {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 24px;
   
}
.cont p {
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 18px;

}

.inst {
    cursor: url('../../img/logo1515.png'), pointer;

}




@keyframes upFoto {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes downFoto {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@media (max-width: 1440px) {
    .header { 
        height: 50%;
    }
    .footer {
        height: 50%;
        margin-top: 20px;
    }
 
}
@media (max-width: 1200px) {
    .header { 
        height: 50%;
    }
    .text h3 {
        font-size: 20px;
    }
    
    .text p {
        font-size: 16px;
    }
    .footer {
        height: 50%;
        margin-top: 20px;
    }
    .footer p {
        font-size: 16px;
    }
    .footer h3 {
        font-size: 20px;
    }
}