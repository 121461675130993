.contacts {
    width: 100%;
    height: auto;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 0;
    background-color: #0e0e0e;
    overflow: hidden;
    
}

.map {
    margin-top: 0;
    height: 260px;
    width: 100%;
    background-size: cover;
}

.text {
    padding-top: 0;
    color: white;
    letter-spacing: 2px;
    width: 100%;  
    z-index: 0;  
}

.text h3 {
    margin-top: 0%;
    font-family: 'Roboto';
    font-weight: 200;
    text-transform: uppercase;
    font-size: 16px;
}


.text p {
    margin: 0 0 0 0;
    width: 100%;
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 14px;
}

.inst {
    size: 30;
}


