.barM {
    width: 100%;
    background-color: #0e0e0e;
    height: auto; 
    position: relative;
    overflow: hidden;
    z-index: 0;
}
.title {
    margin: 120px 5%;
    position: fixed;
    
}

.title h1 {
    font-family: "Empirial";
    color: white;
    font-weight: 100;
    font-size: 40px;
    letter-spacing: 4px;
}
.conteiner{
    width: 100%;
    height: 300px;
    filter: grayscale(40%) opacity(50%);
    position: fixed;
    top:0;
    z-index: -10;
}

.conteiner img{
    width: 800px;
    height: 500px;
    margin-left: -200px;
}
.barPagesM{
    padding-top: 390px;
    z-index: -10;
}
.stock {
    margin-top: 250px;
}
.map {
    margin-top: 250px;
}
