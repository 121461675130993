.stock {

  width: 100%;
  height: 100vh;
  background-color: #0e0e0e;
  position: relative;
  overflow: hidden;
 
}

.stock h1 {

  margin: 0% 40%;
  text-transform: uppercase;
  font-size: 120px;
  font-weight: 100;
  letter-spacing: 2px;
  font-family: 'Roboto';
  position: absolute;
  color: white;

}

.conteiner {

  display: flex;
  height: 80%;
}

.photo {
  width: 40%;
  height: 100%;
  margin-top: 5%;
}

.boxPh {
  width: 600px;
  height: 800px;
  margin: 0 10%;
 
}

.boxPh img {
  width: 90%;
  height: 90%;
 
}

.text {
  width: 60%;
  height: 100%;
  margin-top: 12%;
}

.wrapper {
  display: flex;
  justify-content: space-between;

}

.line {
  border-bottom: 1px solid white;
}

.wrapper p {
  font-family: 'Roboto';
  font-size: 26px;
  font-weight: 100;
  color: white;
}

@media (max-width: 1660px) {
  .stock h1 {
    font-size: 100px;
  }



  .boxPh {
    width: 400px;
    height: 400px;
    margin: 0 20%;

  }
}

@media (max-width: 1200px) {
  .stock h1 {
    font-size: 80px;
  }

  .stock p {
    font-size: 18px;
  }

  .boxPh {
    width: 460px;
    height: 460px;
    margin: 0 0;

  }
}


@media (max-width: 600px) {
  .stock {

    width: 100%;
    height: auto;
    background-color: #0e0e0e;
    position: relative;
    overflow: visible;
 
  }
  .stock h1 {
  
    color: white;
    font-size: 50px;
    font-weight: 300;
    letter-spacing: 2px;
    margin: -20px 30%;
    font-family: 'Roboto';
    z-index: 10;
  }

  .stock p {
    font-family: 'Roboto';
    font-size: 24px;
  }

  .photo {
    width: 100%;
    height: 100%;
    
  }
  .boxPh {
    width: 100%;
    height: auto;
    margin: 0 0;

  }
  .boxPh img {
    width: 100%;
    height: 100%;
   
  }
  

  .conteiner {

    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .text {
    width: 100%;
    height: 100%;
    margin-top: 2%;
  }
  
  .wrapper p {
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 300;
    color: white;
    max-width: 180px;
    text-align: end;
  }
}