
.secondPage {
    opacity: 0.2;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../img/spichki.jpg'), url('../img/spichki_red.jpg')  ;
    z-index: 0;
    filter: blur(5px);
    animation: appere 3s  1 forwards;
}
.secondPage3 {
    opacity: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url('../img/spichki.jpg');
    z-index: 0;
    animation: stop 2s  1 forwards;
}

 
.conteiner {

 
    width: 100%;
    position: relative;
    overflow: hidden;
    
}
.test {
    position: absolute;
    width: 100%;
    height: 100vh;
    opacity: 1;
    
}

@keyframes appere{
    0%{
        opacity: 0.3;
        filter: blur(5px);
    }
    30% {
        opacity: 1;
        filter: blur(3px);
    }
    70% {
        opacity: 1;
        
        filter: blur(1px);
    }
    100%{
        
        opacity: 1;
        filter: blur(0px);
        background-image: url('../img/spichki_red.jpg');
    }

}
@keyframes stop{
    0%{
        opacity: 1;
    }
    100%{
        margin-top: -100vh;
        opacity: 0.1;
    }

}