.barPages {
    width: 100%;
    height: auto;
    background-color: #0e0e0e;
    margin-bottom: 10%;
    position: relative;
}
.barPages p, button{
    z-index: 10;
}


.box1 {

    width: 100%;
    height: calc(100vh + 80px + 120px);
    padding-top: 80px;
    display: flex;  

}

.box1 h2 {
    text-transform: uppercase;
    color: white;
    font-size: 140px;
    font-weight: 100;
    letter-spacing: 20px;
    margin-bottom: 50px;
    font-family: 'Roboto';
    margin-left: 40%;
    position: absolute;
    z-index: 10;
}

.foto1 {
    margin-top: 5%;
    height: 100%;
    width: 60%;
    text-align: end;

}

.foto1 img {
    height: 700px;
    width: 900px;
    filter: grayscale(40%);
    margin-right: 5%;
}

.fotoText {
    height: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 7%;
    align-items: flex-end;
}

.fotoText p {
    color: white;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 100;
    margin-top: -14px;
    z-index: 20;
}

.text {
    margin-top: 5%;
    height: 100%;
    color: white;
    width: 40%;

}

.text img {
    width: 600px;
    height: 780px;
    margin-left: 5%;
    filter: grayscale(40%);
}

.text p {
    color: white;
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 100;
    margin-left: 5%;
}

.button {
    width: 220px;
    height: 55px;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: uppercase;
    color: white;
    background-color: #fb020b;
    filter: grayscale(30%);
    cursor: url('../../img/logo1515.png'), pointer;
    margin-top: 3%;
}
.box2 {
    width: 100%;
    height: calc(100vh + 80px + 120px);
    margin-top: 10%;
    display: flex;
    z-index: 0;
}

.box2 h2 {
    text-transform: uppercase;
    color: white;
    font-size: 140px;
    font-weight: 100;
    letter-spacing: 20px;
    margin-bottom: 50px;
    font-family: 'Roboto';
    margin-left: 20%;
    position: absolute;
    z-index: 10;
}
.foto2 {
    margin-top: 5%;
    height: 100%;
    width: 60%;
    
}

.foto2 img {
    margin-left: 5%;
    height: 700px;
    width: 900px;
    filter: grayscale(40%);

}
.fotoText2 {
    height: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 7%;
    align-items: flex-start;
    margin-left: 5%;
}

.fotoText2 p {
    color: white;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 100;
    margin-top: -14px;
}

.box3 {
    width: 100%;
    height: calc(100vh + 80px + 120px);;
    position: relative;
    z-index: 1;
    margin-top: 10%;

}

.box3 h2 {
    text-transform: uppercase;
    color: white;
    font-size: 120px;
    font-weight: 100;
    letter-spacing: 2px;
    font-family: 'Roboto';
    margin-left: 30%;
    margin-bottom: 0;
    margin-top: 5%;
    position: absolute;
    z-index: 5;

}

.pic1 {
    width: 600px;
    height: 780px;
    position: absolute;
    z-index: 2;
    filter: grayscale(40%);
    margin-top: 4%;
}

.pic2 {
    height: 700px;
    width: 900px;
    position: absolute;
    z-index: 1;
    filter: grayscale(40%);
    margin-top: 16%;
}

.text3 {
    height: 100%;
    color: white;
    width: 45%; 
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    
}


.text3 p {
    
    color: white;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 100;
    margin-top: -14px;
    z-index: 10;
}


@media (max-width: 1660px) {
    .box1 {

        margin-bottom: 20%;
    
    }
    .box2 {

        margin-bottom: 20%;
    
    }
    .box3 {

        margin-bottom: 20%;
    
    }
    .barPages h2 {
        font-size: 100px;
    }

    .conteiner {
        width: 100%;
        margin-left: 0;
    }

    .barPages p {
        font-size: 24px;
    }
}

@media (max-width: 1200px) {
    .barPages h2 {
        font-size: 80px;
    }

    .barPages p {
        font-size: 18px;
    }
    .foto1 img {
        height: 520px;
        width: 700px;
        margin-right: 0%;
    }
    .text img {
        width: 400px;
        height: 520px;
        margin-left: 2%;
        
    }

    .foto2 img {
        height: 520px;
        width: 700px;
        margin-right: 0%;
    }
    .text {
        z-index: 0;
    }
    .text img {
        width: 400px;
        height: 520px;
        margin-left: 0%;
       
    }

    .pic1 {
        width: 400px;
        height: 520px;
        margin-top: 10%;
    }
    
    .pic2 {
        height: 540px;
        width: 700px;
        position: absolute;
        z-index: 1;
        filter: grayscale(40%);
        margin-top: 16%;
    }
    


}