body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Oxygen', 'Brush Script',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .font-link {
    font-family: 'Hanalei Fill', cursive;
  }
  margin: 0 0 0 0;
  width: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Empirial';
  src: 
      url('./fonts/AEmpirial.woff2') format('woff2'),
      url('./fonts/AEmpirial.woff') format('woff');
  
}




@media (max-width: 860px) {

  body {
    height: auto;
      
  }
}