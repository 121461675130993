.navBarM{
  height:80px;
  width: 100%;
  background-color: #0e0e0e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 100;
  
}
.navBarM img {
  margin-left: 10px;
  width: 100px;
  margin-bottom: 5px;
}

.navBarM h1 {
  color: white;
  text-align: center;
  font-family: 'Empirial';
  font-size: 32px;
  font-weight:100;

}
.burgerM{
  width: 100%;
  height:500px;
  background-color: #0e0e0e;
  color: white;
  font-size: 24px;
  font-weight: 100;
  letter-spacing: 2px;
  font-family: 'Roboto';
  position: fixed;
  z-index: 90;
  padding-left: 5%;
}
.item{
  width: 100%;
  height: 400px;
  padding-top: 100px;
}
.menuItem{
  width: 100%;
  height: 20%;
}