.spichki {
    width: 100%;
    height: auto;
    z-index: -10;
    background-color: #0e0e0e;
    overflow: hidden;
}

.title {
    font-weight: 100;
}

.startPic {

    margin-top: 0;
    height: 100vh;
    z-index: 0;
    background-image: url(../../img/spichki_WE.png);
    background-position: center 80%;
    background-repeat: no-repeat;
    width: 100%;


}

.startPic img {
    filter: grayscale(40%) brightness(50%) opacity(90%);
}

.bar1 {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 0px 0px 0px 0px;
    background-color: #0e0e0e;
    z-index: 30;



}

.contacts {
   margin-bottom: 5%;

}


@media (max-width:1660px) {
    .title {

        margin: 200px 300px;

    }
    .stock{
        margin-bottom: 20%;
    }


}

















.oct {
    width: 100%;
    height: 500vh;
    overflow: hidden;

}

.conteiner {
    width: 100%;
    height: 100vh;

    z-index: -5;
    position: fixed;

}

.title {
    position: absolute;
    margin: 300px 300px;
    width: 700px;
    height: 200px;
    z-index: 1;
    color: white;
    animation: title 1s 1 forwards;
    font-family: 'Empirial';
}

.title p {
    margin-top: 0px;
}

.title2 {

    position: fixed;
    margin: -50px 20%;
    z-index: 1;
    color: white;
    letter-spacing: 4px;
    font-family: 'Empirial';
    animation: title2 1s 1 forwards;
}

.wrapper1 {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 80px 0px 0px 0px;

    z-index: -4;

}

.foto1 {
    margin-top: 0;
    height: 100%;
    width: 60%;
    background-size: cover;

}

.foto {
    margin-top: 0;
    height: 100vh;
    width: 50vh;
    opacity: 0;

}


.text {
    margin-top: 0;
    height: 100vh;
    letter-spacing: 4px;
    width: 50%;
    font-family: "Empirial";
    font-weight: 500;
    font-size: 40px;
    color: white;
    text-align: center;

}

.wrapper2 {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 110% 0px 0px 0px;
    justify-content: space-between;
    z-index: -3;

}

.wrapperUp .text {
    margin-top: 0;
    color: white;
    height: 100vh;
    letter-spacing: 4px;
    width: 50%;
    font-family: "Empirial";
    font-weight: 500;
    font-size: 40px;




}

.text h3 {
    margin-top: 30%;
    font-family: 'Roboto';
    font-weight: 500;
    text-transform: uppercase;
    font-size: 32px;


}

.text p {
    margin: 10px 80px;
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 24px;
}

.wrapperUp .textHeader {
    padding-top: 80px;
    color: white;
    height: 100vh;
    letter-spacing: 4px;
    width: 50%;
    font-family: "Empirial";
    font-weight: 500;
    font-size: 40px;
}

.textHeader h3 {
    margin-top: 30%;
    font-family: 'Roboto';
    font-weight: 300;
    text-transform: uppercase;
    font-size: 24px;
}

.textHeader p {
    margin: 10px 80px;
    font-family: 'Roboto';
    font-weight: 200;
    font-size: 18px;
}

.wrapperUp .foto {
    margin-top: 0;
    height: 100vh;
    width: 50%;
    background-size: cover;
    opacity: 0;
    animation: upFoto 3s 1s 1 forwards;
}

.wrapper3 {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 200% 0px 0px 0px;
    transition: all 0.5s;
    justify-content: space-between;
    z-index: -2;
}

.wrapper4 {
    width: 100%;
    height: 100vh;
    display: flex;
    margin: 300% 0px 0px 0px;
    transition: all 0.5s;
    justify-content: space-between;
    z-index: -2;
}


.wrapperUp {
    width: 100%;
    height: 100vh;
    display: flex;
    animation: up 2s 1 forwards;
    position: fixed;
    text-align: center;

    z-index: -1;
}

.wrapperDown {
    width: 100%;
    height: 100vh;
    display: flex;
    animation: down 2s 1 forwards;
    position: fixed;
    justify-content: space-between;
    z-index: -1;
    text-align: center;
}


.wrapperDown .textHeader {
    margin-top: 0;
    color: white;
    height: 100vh;
    letter-spacing: 4px;
    width: 50%;
    font-family: "Empirial";
    font-weight: 500;
    font-size: 40px;
}

.inst {
    cursor: url('../../img/logo1515.png'), pointer;

}


@keyframes up {
    0% {
        margin: 110% 0px 0px 0px;
    }

    100% {
        margin: 0px 0px 0px 0px;
    }
}

@keyframes down {
    0% {
        margin-top: 0
    }

    100% {
        margin-top: 100%;
    }
}

@keyframes upFoto {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes downFoto {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes title {
    0% {
        font-size: 0px;
    }

    100% {
        font-size: 120px;
    }
}

@keyframes title2 {
    0% {
        font-size: 0px;
        opacity: 0;
    }

    100% {
        font-size: 40px;
        opacity: 1;
    }
}

@media (max-width:1660px) {
    .title {

        margin: 200px 300px;

    }


}